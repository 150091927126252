import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`👩‍🎤 Identity`}</h1>
    <ul>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{` provides a number of ways to handle `}<a parentName="li" {...{
          "href": "/participant",
          "title": "participant"
        }}>{`participant`}</a>{` `}<a parentName="li" {...{
          "href": "/Identity",
          "title": "👩‍🎤 Identity"
        }}>{`👩‍🎤 Identity`}</a>{``}</li>
      <li parentName="ul">{`Having a plan for handling `}<a parentName="li" {...{
          "href": "/Identity",
          "title": "👩‍🎤 Identity"
        }}>{`👩‍🎤 Identity`}</a>{` is important for all `}<a parentName="li" {...{
          "href": "/conversation",
          "title": "conversation"
        }}>{`conversation`}</a>{` `}<a parentName="li" {...{
          "href": "/owners",
          "title": "owners"
        }}>{`owners`}</a>{``}</li>
      <li parentName="ul">{`Generally, there are two paths: anonymous and non-anonymous, where non-anonymous may also be verified.`}</li>
      <li parentName="ul">{`By default, `}<a parentName="li" {...{
          "href": "/participants",
          "title": "participants"
        }}>{`participants`}</a>{` will be prompted to log in using `}<a parentName="li" {...{
          "href": "/Facebook-auth",
          "title": "Facebook auth"
        }}>{`Facebook auth`}</a>{` or `}<a parentName="li" {...{
          "href": "/Twitter-auth",
          "title": "Twitter auth"
        }}>{`Twitter auth`}</a>{` when they are commenting, but will be allowed to `}<a parentName="li" {...{
          "href": "/vote",
          "title": "vote"
        }}>{`vote`}</a>{` without logging in for a low friction experience. This is aligned with reducing the number of features (comments) to increase `}<a parentName="li" {...{
          "href": "/density",
          "title": "density"
        }}>{`density`}</a>{` in the `}<a parentName="li" {...{
          "href": "/polis-opinion-matrix",
          "title": "polis opinion matrix"
        }}>{`polis opinion matrix`}</a>{`.`}</li>
      <li parentName="ul"><strong parentName="li">{`Anonymous`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`In the `}<a parentName="li" {...{
              "href": "/admin-interface",
              "title": "admin interface"
            }}>{`admin interface`}</a>{`, `}<a parentName="li" {...{
              "href": "/conversation",
              "title": "conversation"
            }}>{`conversation`}</a>{` `}<a parentName="li" {...{
              "href": "/owners",
              "title": "owners"
            }}>{`owners`}</a>{` can decide to allow `}<a parentName="li" {...{
              "href": "/participants",
              "title": "participants"
            }}>{`participants`}</a>{` to `}<a parentName="li" {...{
              "href": "/vote",
              "title": "vote"
            }}>{`vote`}</a>{` and / or `}<a parentName="li" {...{
              "href": "/comment",
              "title": "comment"
            }}>{`comment`}</a>{` without being prompted to log in using `}<a parentName="li" {...{
              "href": "/Facebook-auth",
              "title": "Facebook auth"
            }}>{`Facebook auth`}</a>{` or `}<a parentName="li" {...{
              "href": "/Twitter-auth",
              "title": "Twitter auth"
            }}>{`Twitter auth`}</a>{``}</li>
          <li parentName="ul">{`If logging in is not required, a cookie is set (see: `}<a parentName="li" {...{
              "href": "/cookies",
              "title": "cookies"
            }}>{`cookies`}</a>{`) so that participants can vote and comment, and be connected to their voting record when they return on the same device`}</li>
          <li parentName="ul">{`If participants return on a new device, they will be counted as new participants and the voting records will not be linked`}</li>
          <li parentName="ul">{`Using an incognito window, participants could, if anonymous, vote an unlimited amount of times (this is still possible if they log in with Twitter or Facebook auth, but in that case there is some rate limit on the number of fake accounts they would be able to create — Twitter enforces a 1:1 relationship between emails and accounts, for instance)`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Non-anonymous`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`In some cases, a conversation owner may already know who their participants are.`}</li>
          <li parentName="ul">{`If conversations owners want to link existing metadata, for instance age and gender, that they have in an existing database or simply a CSV, this can be accomplished using an `}<a parentName="li" {...{
              "href": "/xid",
              "title": "xid"
            }}>{`xid`}</a>{` (see `}<a parentName="li" {...{
              "href": "/xid",
              "title": "xid"
            }}>{`xid`}</a>{` page for details)`}</li>
        </ul>
      </li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/anonymous-but-verified",
          "title": "anonymous but verified"
        }}>{`anonymous but verified`}</a>{``}
        <ul parentName="li">
          <li parentName="ul">{`In other cases, conversation owners will want to ensure`}
            <ul parentName="li">
              <li parentName="ul">{`that only certain participants (ie., from a certain email list) are able to participate but not people outside of this`}</li>
              <li parentName="ul">{`but owners also want participants to be anonymous`}</li>
            </ul>
          </li>
          <li parentName="ul">{`In this case a conversation owner would go about `}<a parentName="li" {...{
              "href": "/creating-single-use-urls",
              "title": "creating single use urls"
            }}>{`creating single use urls`}</a>{``}</li>
        </ul>
      </li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/pol.is",
          "title": "pol.is"
        }}>{`pol.is`}</a>{` as a platform, does not reveal the identity of participants to owners unless owners use an `}<a parentName="li" {...{
          "href": "/xid",
          "title": "xid"
        }}>{`xid`}</a>{`, in which case identities are linked to the participant record via that shared parameter.`}</li>
      <li parentName="ul">{`GDPR is covered in the `}<a parentName="li" {...{
          "href": "/FAQ",
          "title": "FAQ"
        }}>{`FAQ`}</a>{``}</li>
    </ul>
    <h3>{`Old content, to clean up`}</h3>
    <ul>
      <li parentName="ul">{`There are different authorization scenarios you may encounter when using pol.is.`}
        <ul parentName="li">
          <li parentName="ul">{`You may need to know who is who, or you may not`}</li>
          <li parentName="ul">{`It may be important to you to make sure people aren't voting more than once, or it may not`}</li>
          <li parentName="ul">{`You may already know who your participants are from some other identity system, or you may not`}</li>
          <li parentName="ul">{`You may want to restrict participation to those who are seeing the conversation on a certain domain, logged in through some other authorization system`}</li>
        </ul>
      </li>
      <li parentName="ul">{`By default as of this writing (July, 2020) users can vote anonymously but commenting requires connecting Facebook or Twitter auth.`}
        <ul parentName="li">
          <li parentName="ul">{`The goal is to not stop people from producing votes, so zero friction`}</li>
          <li parentName="ul">{`All four of these options — require auth to comment or vote, and which social buttons to show if any, can be disabled in `}<a parentName="li" {...{
              "href": "/conversation-configuration",
              "title": "conversation configuration"
            }}>{`conversation configuration`}</a>{``}</li>
        </ul>
      </li>
      <li parentName="ul">{`If anyone is able to vote and comment without authorization, the conversation is more or less anonymous`}
        <ul parentName="li">
          <li parentName="ul">{`participants could have connected social in a previous conversation or could even have their own `}<a parentName="li" {...{
              "href": "/owner",
              "title": "owner"
            }}>{`owner`}</a>{` account, and because of this there isn't a way in the system presently to guarantee the database doesn't know about a participant already`}</li>
        </ul>
      </li>
      <li parentName="ul">{`If you already know who your users are and want to link them when they participate, see `}<a parentName="li" {...{
          "href": "/xid",
          "title": "xid"
        }}>{`xid`}</a>{``}</li>
      <li parentName="ul">{`👻 Polis does support, but not officially (hidden feature that has to be turned on) sending one time usage tokens (via email for instance)`}</li>
      <li parentName="ul">{`👻 Polis does support, but not officially (hidden feature that has to be turned on) whitelisting domains — ie., a conversation will not work unless it is accessed on a domain, which allows embedding a conversation inside of a site's login flow`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      